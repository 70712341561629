import React, { MouseEvent } from 'react';


interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  testId?: string;
}

const CloseSmallIcon = ({ className, onClick, testId }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} onClick={onClick} data-testid={testId}>
      <g clip-path="url(#clip0_1100_11)">
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#191B1E"/>
      </g>
      <defs>
        <clipPath id="clip0_1100_11">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseSmallIcon;
