import { IUserLocation } from 'utils/user-location/types';
import { generateUrlsFromLastSearch, LastSearchUrlsResponse } from 'data/search-predictions';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';
import { ThemeName } from 'themes';
import { getSupportedUserLocation } from 'utils/user-location/user-location';

interface GenerateUrlsResult {
  homesForSaleUrl: string;
  mapViewUrl: string;
  soldPricesUrl?: string;
}

/**
 * Generates URLs for homepage components based on user's last search, location, or defaults
 *
 * @param lastSearchLocation - User's last search location if available
 * @param userLocation - User's current location
 * @param siteLocation - Current site location ('US' or 'CA')
 * @param tenantName - Tenant name
 * @param hideImagelessListings - Whether to hide listings without images
 * @param defaultUrls - Optional override for default URLs
 * @returns Object containing generated URLs
 */
export const generateHomepageUrls = async (
  lastSearchLocation: any,
  userLocation: IUserLocation,
  siteLocation: string,
  tenantName: ThemeName,
  hideImagelessListings?: boolean,
  defaultUrls?: { homesForSaleUrl?: string, mapViewUrl?: string, soldPricesUrl?: string }
): Promise<GenerateUrlsResult> => {
  const imageQuery = hideImagelessListings ? '/filter?has-image=true' : '';

  // Generate default URLs based on site location
  const baseDefaultUrls = {
    homesForSaleUrl: `/${siteLocation?.toLowerCase()}-real-estate`,
    mapViewUrl: '/search',
    soldPricesUrl: `/${siteLocation?.toLowerCase()}-real-estate/sold`,
  };

  // Merge with any provided overrides
  const result = { ...baseDefaultUrls, ...defaultUrls };

  if (lastSearchLocation) {
    try {
      const response: LastSearchUrlsResponse = await generateUrlsFromLastSearch(lastSearchLocation, hideImagelessListings);
      result.homesForSaleUrl = response?.homesForSaleUrl || result.homesForSaleUrl;
      result.mapViewUrl = response?.mapViewUrl || result.mapViewUrl;
      if (response?.soldPricesUrl) {
        result.soldPricesUrl = response.soldPricesUrl;
      }
    } catch (error) {
      console.error('Error generating URLs from last search:', error);
    }
  } else if (userLocation) {
    const supportedLocation = getSupportedUserLocation(tenantName, userLocation);
    const updatedHomeUrl = `/${supportedLocation.slug}-real-estate`;

    result.homesForSaleUrl = updatedHomeUrl + imageQuery;

    result.mapViewUrl = `/search?latitude=${supportedLocation.latitude}&longitude=${supportedLocation.longitude}&area-name=${supportedLocation.name}&slug=${supportedLocation.slug}`;

    if (result.soldPricesUrl) {
      result.soldPricesUrl = `${updatedHomeUrl}/sold${imageQuery}`;
    }
  } else {
    // Fall back to country default
    const fallbackLocation = siteLocation === 'US' ? defaultUSCityPayload : defaultCACityPayload;
    const updatedHomeUrl = `/${fallbackLocation.slug}-real-estate`;

    result.homesForSaleUrl = updatedHomeUrl + imageQuery;
    result.mapViewUrl = `/search?latitude=${fallbackLocation.latitude}&longitude=${fallbackLocation.longitude}&area-name=${fallbackLocation.name}&slug=${fallbackLocation.slug}`;

    if (result.soldPricesUrl) {
      result.soldPricesUrl = `${updatedHomeUrl}/sold${imageQuery}`;
    }
  }

  return result;
};
