import React, { lazy, useEffect } from 'react';
import { ThemeNames } from 'types/themes';
import { LinkDataType } from './internal-links';
import HomePageZoocasa from './themed-home-page-index/homepage-zoocasa';
import { RouteName } from 'components/dynamic-page/route-matchers';

import type { INamedContents } from 'pages';
import type { ListingCardData } from 'components/listing-card';
import { HeadDataType } from 'components/head-data';
import { HomeConfig, TenantName } from 'tenants';

export interface HomePageProps {
  isCrawler: boolean;
  featuredHomesMlsNums: INamedContents['featuredHomesMlsNums'];
  isNotificationBannerHidden: boolean;
  availableHomeListings: ListingCardData[];
  availableHomeListingsMeta: number;
  availableHomeListingsLocation: string;
  internalLinksData: LinkDataType[];
  // Optional props tenantConfig is optional now, but needs to be made required in the future
  tenantHomeConfig?: HomeConfig;
  isExpandedArea?: boolean;
  testingTheme?: ThemeNames;
  maintenance?: Maintenance;
  routeName?: RouteName;
  tenantName?: TenantName;
  headerData?: HeadDataType;
}

export interface Maintenance {
  isMaintenance: boolean;
  maintenanceStartTime: string;
  maintenanceEndTime: string;
  maintenanceDate: string;
}

const HomePageExpCA = lazy(() => import('./themed-home-page-index/homepage-exp-ca'));
const HomePageExpUS = lazy(() => import('./themed-home-page-index/homepage-exp-us'));
const HomePageExpInService = lazy(() => import('./themed-home-page-index/homepage-exp-in-service'));
const HomePageExpCommercial = lazy(() => import('./themed-home-page-index/homepage-exp-commercial'));

export default function HomePage(props: HomePageProps) {

  // manually set the maintenance props for the homepage and change when needed -- values are currently dummy values
  const maintenance = { isMaintenance: false, maintenanceStartTime: 'Start time', maintenanceEndTime: 'End time', maintenanceDate: 'Date' };

  props = { ...props, maintenance: maintenance };

  switch (props.tenantName) {
  case ThemeNames.EXP_REALTY_CA:
    return <HomePageExpCA {...props} />;
  case ThemeNames.EXP_REALTY_US:
    return <HomePageExpUS {...props} />;
  case ThemeNames.EXP_IN_SERVICE:
    return <HomePageExpInService {...props} />;
  case ThemeNames.EXP_COMMERCIAL:
    return <HomePageExpCommercial {...props} />;
  default:
    return <HomePageZoocasa {...props} />;
  }
}
