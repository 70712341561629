import React, { MouseEvent } from 'react';


interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  testId?: string;
}

const MaintenanceIcon = ({ className }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className={className}>
      <path d="M12.1124 12.5923L12.6039 12.1026L7.00413 6.5235C7.22648 6.25533 7.39921 5.95218 7.52233 5.61405C7.64497 5.27593 7.7063 4.92031 7.7063 4.54721C7.7063 3.67275 7.39921 2.93237 6.78505 2.32607C6.17042 1.71978 5.42425 1.41663 4.54654 1.41663H4.371L6.53016 3.56781L3.54595 6.5235L1.38678 4.37232V4.5647C1.38678 5.43916 1.69386 6.17954 2.30803 6.78583C2.92266 7.39213 3.66883 7.69528 4.54654 7.69528C4.92103 7.69528 5.27516 7.63395 5.60892 7.51129C5.94221 7.3891 6.24344 7.21724 6.51261 6.99571L12.1124 12.5923ZM11.1294 13.5892L6.28441 8.76212C6.01524 8.86706 5.73742 8.94867 5.45093 9.00697C5.16398 9.06527 4.86837 9.09442 4.56409 9.09442C3.28849 9.09442 2.20902 8.65415 1.32569 7.77363C0.441898 6.89357 0 5.8181 0 4.54721C0 4.05751 0.0732595 3.5853 0.219779 3.13058C0.36583 2.67586 0.579289 2.25612 0.860156 1.87135L3.54595 4.54721L4.54654 3.56781L1.86075 0.874463C2.25864 0.582976 2.68579 0.364243 3.1422 0.218266C3.59861 0.0727554 4.06672 0 4.54654 0C5.82214 0 6.90184 0.44003 7.78564 1.32009C8.66897 2.20062 9.11063 3.27632 9.11063 4.54721C9.11063 4.85036 9.08138 5.15047 9.02286 5.44756C8.96435 5.74511 8.87658 6.02797 8.75955 6.29614L13.587 11.1057C13.8561 11.3739 13.9937 11.7031 13.9998 12.0935C14.0054 12.4843 13.8737 12.8138 13.6045 13.082L13.1305 13.5717C12.8614 13.8515 12.5307 13.9942 12.1384 13.9998C11.7466 14.0059 11.4102 13.869 11.1294 13.5892Z" fill="#191B1E"/>
    </svg>
  );
};

export default MaintenanceIcon;
