import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import CloseSmallIcon from 'components/icon/close-small-icon';
import MaintenanceIcon from 'components/icon/maintenance-icon';
import type { Maintenance } from 'components/home-page';

interface MaintenanceProps {
  maintenance?: Maintenance;
}

const Maintenance = ({ maintenance } : MaintenanceProps) => {
  const startTime = maintenance.maintenanceStartTime;
  const endTime = maintenance.maintenanceEndTime;
  const endDate = maintenance.maintenanceDate;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const sessionKey = `${endDate} ${endTime}`; // Unique key
    const storedSessionKey = sessionStorage.getItem('maintenanceEndDate');
    // if the stored endDate matches the current one, keep it hidden
    if (storedSessionKey === sessionKey) return;

    setIsVisible(true);
  }, [startTime, endTime, endDate]);

  const handleClose = () => {
    const sessionKey = `${endDate} ${endTime}`; // Unique key
    sessionStorage.setItem('maintenanceEndDate', sessionKey);
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={styles['notification-banner']}>
      <span className={styles['notification-title']}><MaintenanceIcon className={styles['maintenance-icon']} />Scheduled Maintenance</span>
      <div className={styles['notification-content']}>
        <p>We’re enhancing our systems on <strong>{endDate}</strong> from <strong>{startTime}</strong> to <strong>{endTime}</strong>. Some features may be unavailable during this time. Thanks for your patience!</p>
        <button className={styles['close-button']} onClick={handleClose}>
          Got it!
        </button>
      </div>
      <CloseSmallIcon className={styles['close-icon']} onClick={handleClose} testId='close-banner' />
    </div>
  );
};

export default Maintenance;
